@import '../../../sass/variables';

.standard-text {
    a {
        &:hover {
            text-decoration: underline;
        }
    }
    p {
        padding-bottom: 10px;
    }
}
@media only screen and (max-width: 768px) {
    .standard-text {
        text-align: left !important;

        .text-left,
        .text-right,
        .text-center {
            text-align: left !important;
        }
    }
}

/* interactions */

.standard-text + .standard-text-no-title,
.standard-text + .text-media-variable-no-title,
.standard-text + .media-text-columns-no-title,
.standard-text + .standard-table-no-title {
    padding-top: 0;
}

.standard-text + .media-text-columns-no-title-no-text {
    margin-top: -45px;
    padding-top: 0;
}

@media only screen and (max-width: 768px) {
    .standard-text + .standard-text-no-title,
    .standard-text + .text-media-variable-no-title,
    .standard-text + .media-text-columns-no-title,
    .standard-text + .standard-table-no-title {
        margin-top: 0;
        padding-top: 0;
    }

    .standard-text + .media-text-columns-no-title-no-text {
        margin-top: -5px;
    }
}
